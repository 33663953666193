@use '../abstracts/' as *;

.login-background {
  background-image: url("../../assets/images/LoginPage/Login_Web_Background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: fixed;
  height: 100%;
  width: 100%;

  .left-overlay {
    width: 50%;
    background-color: hsla(56, 89, 96, 50%);
    height: 100vh;

    @include mq(tablet) {
      height: 100%;
      width: 100%;
    }
  }

  @include mq(tablet) {
    background-image: url("../../assets/images/LoginPage/Login_Tablet_Background.svg");
  }
  @include mq(mobile) {
    background-image: url("../../assets/images/LoginPage/Login_Mobile_Background.svg");
  }

  .content {
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mq(tablet) {
      justify-content: center;
      height: 70vh;
    }
    @include mq(mobile) {
      height: 70vh;
    }

    img {
      width: 30vw;
      @include mq(tablet) {
        width: 60vw;
      }
      @include mq(mobile) {
        width: 80vw;
      }
    }

    h2 {
      font-size: clamp(1rem, 2vw, 6rem);
      color: $dark-brown-color;

      @include mq(tablet) {
        font-size: clamp(1rem, 5vw, 8rem);
      }
    }

    .sign-in-container {
      display: flex;
      flex-direction: column;
      margin-top: 25%;

      @include mq(tablet) {
        margin-top: 15%;
      }
      @include mq(mobile) {
        margin-top: 15rem;
      }

      input {
        width: 30vw;
        padding: 12px 20px;
        margin: 8px 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid $dark-brown-color;
        color: $dark-brown-color;
        font-size: clamp(1rem, 3.5vw, 2rem);
        background-color: transparent;
        text-align: center;

        &::placeholder {
          color: $dark-brown-color;
        }

        &:focus {
          outline: none;
        }

        @include mq(tablet) {
          width: 40vw;
        }
        @include mq(mobile) {
          width: 50vw;
        }
      }

      .error-message {
        color: red;
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
      }

      button {
        font-size: 28px;
        color: $dark-brown-color;
        font-weight: bolder;
        height: 50px;
        border-radius: 5px;
        border: 3px solid $dark-brown-color;
        background: transparent;
        margin-top: 1rem;

        &:hover {
          background-color: $light-background-color-overlay;
        }
      }
    }
  }
}
