@use "../abstracts/" as *;

.language-container {
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;

  select {
    background-color: $modal-light-background-color-overlay;
    border: 1px solid $dark-brown-color;
    color: $dark-brown-color;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1rem;
    
  }
}
