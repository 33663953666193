@font-face {
  font-family: "FriendsCapitaine-Bold";
  src: local("FriendsCapitaine-Regular"), url("../../assets/Fonts/FriendsCapitaine-Bold.otf") format("opentype");
}
@font-face {
  font-family: "FriendsCapitaine-SemiBold";
  src: local("FriendsCapitaine-Regular"), url("../../assets/Fonts/FriendsCapitaine-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "FriendsCapitaine-Regular";
  src: local("FriendsCapitaine-Regular"), url("../../assets/Fonts/FriendsCapitaine-Regular.otf") format("opentype");
}
@font-face {
  font-family: "FriendsCapitaine-RegularItalic";
  src: local("FriendsCapitaine-Regular"),
    url("../../assets/Fonts/FriendsCapitaine-RegularItalic.otf") format("opentype");
}
