@import "./base/";
@import "./abstracts/";
@import "./components/";
@import "./pages/";

body,
html {
  overflow: auto !important;
  max-height: 100% !important;
}

h1,
h2,
h3,
button,
p,
span,
select,
input {
  font-family: "FriendsCapitaine-Regular";
}
