@use 'sass:map';

$breakpoints: (
  mobile: 697px,
  tablet: 991px,
  web: 1312px,
);

@mixin centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

//Media query
@mixin mq($key) {
  $size: map.get($breakpoints, $key);

  @media (max-width: $size) {
    @content;
  }
}
