@use '../abstracts/' as *;

.homePage {
  h2 {
    color: $dark-brown-color;
    font-size: clamp(1rem, 1.9vw, 2rem);

    @include mq(tablet) {
      font-size: $text-h2-size-tablet;
    }
    @include mq(mobile) {
      font-size: $text-h2-size-tablet;
      margin-top: 0.3rem;
    }
  }

  p {
    color: $light-brown-color;
    font-size: $text-size;
    text-align: center;
    padding: 0 clamp(1rem, 4vw, 6rem);

    @include mq(tablet) {
      font-size: $text-size-tablet;
    }

    @include mq(mobile) {
      font-size: $text-size-mobile;
    }
  }

  .homePage-container {
  @include centerItem;
    min-width: 100vw;
    height: 85vh;
    background: url("../../assets/images/homePage/HomePage_Background.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-right: clamp(0.5rem, 14vw, 15rem);
    padding-left: clamp(0.5rem, 14vw, 15rem);

    @include mq(tablet) {
      background: url("../../assets/images/homePage/Home_Background_Tablet.svg");
      padding-right: clamp(0.5rem, 10vw, 15rem);
      padding-left: clamp(0.5rem, 10vw, 15rem);
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mq(mobile) {
      background: url("../../assets/images/homePage/Home_Background_Mobile.svg");
      background-size: cover;
      background-repeat: no-repeat;
      height: 45rem;
    }

    .homePage-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $light-background-color-overlay;
      border-radius: 20px;
      padding: 5% 0;
    }

    .homePage-button {
      background: transparent;
      border: solid $dark-brown-color;
      border-radius: 5px;
      color: $dark-brown-color;
      font-weight: bold;
      cursor: pointer;
      margin: 1.2rem 0;

      @include mq(mobile) {
        margin: 1rem 0;
      }

      p {
        font-weight: bold;
        letter-spacing: 5px;
        font-size: $button-text-size;
        padding: 1rem;

        @include mq(mobile) {
          font-size: $text-size-mobile;
        }
      }
    }

    .homePage-text {
      p {
        margin: 1.1rem 0;
      }
    }

    .homePage-snallkalendern {
      width: clamp(230px, 30vw, 500px);

      @include mq(tablet) {
        width: clamp(230px, 50vw, 500px);
      }
    }
  }

  footer {
    position: relative;
    padding: 0 2em;
    max-width: 100vw;
    max-height: 15vh;
  }

  .footer-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    @include mq(tablet) {
      justify-content: center;
      margin-top: 5%;
    }

    @include mq(mobile) {
      justify-content: space-evenly;
    }

    img {
      &:nth-of-type(2) {
        margin-left: auto;
        @include mq(tablet) {
          margin-left: 0;
        }
      }
      &:nth-of-type(5) {
        margin-left: auto;
        @include mq(tablet) {
          margin-left: 0;
        }
      }
    }
  }

  .column-line {
    border-left: 1px solid grey;
    height: 60px;
    margin: 0 3rem;

    @include mq(mobile) {
      margin: 0.5rem;
    }
  }

  .logo-style {
    width: clamp(1rem, 16vw, 5rem);

    @include mq(tablet) {
      width: clamp(1rem, 16vw, 10rem);
    }
    @include mq(mobile) {
      width: clamp(4rem, 10vw, 10rem);
    }
  }

  .mrs-mighetto-style {
    width: clamp(1rem, 9vw, 8rem);

    @include mq(tablet) {
      width: clamp(1rem, 20vw, 11rem);
    }

    @include mq(mobile) {
      width: clamp(5.5rem, 16vw, 9rem);
    }
  }

  .bunny-illustrator {
    margin: 0.5rem 0;
    width: clamp(45px, 3vw, 70px);
    @include mq(tablet) {
      display: none;
    }
  }
  .girls-style {
    margin: 0.5rem 0;
    width: clamp(50px, 4vw, 90px);
    @include mq(tablet) {
      display: none;
    }
  }
}
