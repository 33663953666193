@use "../abstracts/" as *;

.card {
  @include centerItem;
  position: relative;
  margin-top: 40px;
  border: $calendarWindow-border-color;
  border-radius: 10%;
  text-align: center;
  cursor: pointer;

  h3 {
    color: $calendarWindow-text-color;
    font-size: 24px;
  }
}
