@use "../abstracts/" as *;

.modal-container {
  @include centerItem;

  .overlay-style {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $light-background-color-overlay-modal;
    -webkit-animation: faceInOverlay 0.8s 1;
    -webkit-animation-timing-function: linear;
    animation: fadeInOverlay 0.8s 1;
    animation-timing-function: linear;

    @keyframes fadeInOverlay {
      from {
        background-blend-mode: none;
        opacity: 0;
      }
      to {
        background-blend-mode: overlay;
        opacity: 1;
      }
    }
  }

  .modal-style {
    position: absolute;
    top: 20%;
    width: 350px;
    height: 450px;
    box-shadow: 2px 2px 4px grey;
    border-radius: 20px;
    border: none;
    -webkit-animation: fadeInContent 0.8s 1;
    -webkit-animation-timing-function: linear;
    animation: fadeInContent 0.8s 1;
    animation-timing-function: linear;

    @keyframes fadeInContent {
      from {
        opacity: 0;
        pointer-events: none;
      }
      to {
        opacity: 1;
        pointer-events: none;
      }
    }
  }

  .modal-date {
    color: $lighter-background-color;
    position: relative;
  }
  .modal-text {
    color: $dark-brown-color;
  }

  .close-button {
    display: flex;
    justify-content: flex-end;
    position: inherit;
    right: 0;
    z-index: 1000;
    padding: 1em;
    font-size: 24px;
    color: $dark-brown-color;
    font-weight: bold;
    cursor: pointer;
  }

  .modal-content-hidden {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 450px;
    cursor: pointer;
    -webkit-animation: textAnimation 1s 1;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    animation: textAnimation 1s 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes textAnimation {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    99% {
      pointer-events: none;
    }
    100% {
      opacity: 1;
    }
  }

  .show-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 450px;
    border-radius: 20px;
    padding: 0 1.5rem;
    font-size: $modal-text-size;
    font-weight: bold;
    color: $dark-brown-color;
    text-align: center;
    cursor: pointer;
    -webkit-animation: showContent 1.5s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    animation: showContent 1.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    overflow-y: scroll;
    scrollbar-color: black;

    &::-webkit-scrollbar {
      display: none;
    }

    h2 {
      margin-top: 2rem;
    }

    p {
      margin: 1rem 0;
    }
  }

  @keyframes showContent {
    0% {
      opacity: 0;
      pointer-events: none;
    }
    99% {
      pointer-events: none;
    }
    100% {
      opacity: 1;
      background: $modal-light-background-color-overlay;
    }
  }
}
